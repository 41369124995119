@import url('https://fonts.googleapis.com/css2?family=Londrina+Sketch&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Londrina+Sketch&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Londrina+Sketch&family=Nanum+Gothic+Coding:wght@400;700&display=swap');

body {
    background-image: url(goodeats.png);
    margin: 0;
    padding: 0;
}

.goodeats {
    background-color: #ffc8a1e6;
    width: auto;
    height: 70vh;
    border: 1px solid #491709;
    padding: 20px;
}

h1 {
    font-family: "Londrina Sketch", sans-serif;
    font-weight: 800;
    font-size: 100px;
    color: #ad5a43;
    margin: 20px 0;
}

p {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 20px;
}

header {
    background-color: #aa5934;
    padding: 10px 20px;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-container img {
    width: 50px;
    height: 50px;
}

.nav-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.nav-links li {
    margin-left: 20px;
}

.nav-links li a {
    font-family: "Nanum Gothic Coding", monospace;
    font-weight: 400;
    color: #ffe2ce;
    text-decoration: none;
    font-size: 20px;
    padding: 10px 15px;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.nav-links li a.button {
    background-color: #883b25;
}

.nav-links li a.button:hover {
    background-color: #491709;
    border-color: #ffe2ce;
}

main {
    padding: 20px;
    text-align: center;
}

div.gallery img {
    width: 320px;
    height: 240px;
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease;
    align-items: left;
}

div.gallery img:hover {
    transform: scale(1.05);
}

div.desc {
    padding: 15px;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 400;
}

* {
    box-sizing: border-box;
}

.responsive {
    padding: 0 6px;
    width: 100%;
}

@media only screen and (max-width: 700px) {
    .responsive {
        width: 49.99999%;
        margin: 6px 0;
    }
}

@media only screen and (max-width: 500px) {
    .responsive {
        width: 100%;
    }
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

form {
    display: inline-block;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

form label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
}

form input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    background-color: #ad5a43;
    margin: 0px;
    color: #ffe2ce;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
button:hover {
    background-color: #883b25;
}


.form-box {
    background-color: #6e5c3900;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    min-height: 200px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

form {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}


.buttonform {
    font-family: "Nanum Gothic Coding", monospace;
    color: #ffe2ce;
    text-decoration: none;
    font-size: 17px;
    border: 5px solid transparent;
    border-radius: 5px;
}

.buttonform:hover {
    background-color:#883b25;
    border-color: #491709;

}

h2 {
    font-family: "Londrina Sketch", sans-serif;
    font-weight: 600;
    font-size: 40px;
    letter-spacing: 2px;
    color: #ad5a43;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.recipe-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.recipe-name {
    font-family: "Londrina Sketch", sans-serif;
    font-weight: 800;
    font-size: 40px;
    color: #ad5a43;
    margin-bottom: 20px;
    text-align: center;
}

.recipe-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
}

.recipe-image {
    width: 40%; /* Adjust as needed */
    max-width: 300px;
    height: auto;
    border-radius: 10px;
}

.recipe-ingredients {
    flex: 1;
    font-family: "Nanum Gothic Coding", monospace;
    font-size: 16px;
    color: #491709;
    text-align: left;
}

.recipe-instructions {
    margin-top: 20px;
    font-family: "Caveat", cursive;
    font-size: 18px;
    color: #491709;
    line-height: 1.5;
    text-align: left;
}

#in-line {
    display: flex;
    align-items: center; /* Align items vertically */
    gap: 10px; /* Optional: Add spacing between h3 and button */
}

#in-line h3 {
    margin: 0; /* Remove default margin from h3 for proper alignment */
}

.scrollable-container {

    flex: 3;
    /* width: 75%; */
    height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .recipe-card {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .container {

    display: flex;
    gap:10px;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    height: 70vh;
    overflow: hidden;
  }

  ul.nav-links button{
    font-size: 20px;
  }

  .right-container {
    flex:1;
    /* width: 25%; */
    height: 95%;

    overflow-y: hidden;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .right-container form{
height: 100%;
  }

  .button-box {
    height: auto;
    display: flex;
    gap: 40px; /* Space between the two items */
    flex-direction: row; /* Keep items side by side */
    justify-content: center; /* Align items horizontally towards the right (optional) */
    align-items: flex-end; /* Align items vertically to the bottom */
    height: 100%; /* Ensure the container has a height to align items properly */
    padding-bottom: 10px; /* Add space from the bottom of the container */
  }
  